<template>

	<div> <!-- this outer div only needed if outer-wrapper is not the root element (see last section) -->
		<div class="outer-wrapper"> <!-- Outer wrapper handles global media queries (max screen widths etc) -->

			<!-- nav-spacing contains any top navigation elements (and ensures there is adequate space at the top of the page to prevent overlap with following elements) -->
			<div id="top-nav"> <!-- No need to include this wrapper, if nav elements should overlap below content (e.g. header images) -->
				<!-- include top-nav even if no top nav elements on page, to ensure consistent spacing for headers below -->
				<!-- example "back" and "close" elements. Global styles for consistency -->
				<router-link :to="{ name: 'Home' }">
					<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
				</router-link>
				<router-link :to="{ name: 'Home' }">
					<img src="@/assets/close-icon.png" alt="close" class="close-icon"/>
				</router-link>

				<!-- example "home" and "menu" elements. Global styles for consistency -->
				<TopLeftIcon icon="main" />    
				<Menu />

			</div>

			<div class="inner-wrapper"> <!-- Inner wrapper handles global padding -->
				<!-- main headings (first on page) structured like this with global styles -->
				<div class="heading-section">
					<h1 class="heading3">Heading here. </h1>
				</div>
				The h1 tag can be replaced with whatever makes most sense on the page (SEO etc). The heading3 class can override the tag styling with global styles (heading1 - heading6 options) <br> <br>

				<!-- content goes in here -->
				This inner-wrapper content has padding. Probably where most text content goes. <br> <br>

			</div>

			This content (outer-wrapper) will cover the full width of the responsive media queries. NO padding left/right. Probably mostly used for images / sliders / custom elements.
			Please try resize the screen between desktop and mobile sizes  <br> <br>
			<div class=""> 
				<!-- Full width content can go here, outside of inner wrappers -->
				<!-- This content is still limitted to the outer-wrapper width (may not be full browser width, depending on current screen size -->
				More content inside outer-wrapper. <br> <br>
			</div>

			<div class="inner-wrapper"> <!-- can reopen a new inner-wrapper, to go back to padded content --> 
				<!-- content goes in here -->
				More content inside inner-wrapper <br> <br>
			</div>
				
		</div><!-- end of outer wrapper -->

		<div class=""> 
			<!-- Full width content can go here, outside of outer wrapper -->
			<!-- this will should always be full browser width -->
			Content that is outside of outer-wrapper is full width.
			Please try resize the screen between desktop and mobile sizes  <br> <br>

			The outer-wrapper can be reopened (below) to continue content that isn't full-width <br> <br> 
		</div>

		<div class="outer-wrapper"> <!-- Outer wrapper handles global media queries (max screen widths etc) -->

			<div class="inner-wrapper"> <!-- Inner wrapper handles global padding -->
				<!-- content goes in here -->
				This inner-wrapper content has padding. Probably where most text content goes. <br> <br>

			</div>

			This content (outer-wrapper) will cover the full width of the responsive media queries. NO padding left/right. Probably mostly used for images / sliders / custom elements.
			Please try resize the screen between desktop and mobile sizes  <br> <br>
			<div class=""> 
				<!-- Full width content can go here, outside of inner wrappers -->
				<!-- This content is still limitted to the outer-wrapper width (may not be full browser width, depending on current screen size -->
				More content inside outer-wrapper. <br> <br>
			</div>

			<div class="inner-wrapper"> <!-- can reopen a new inner-wrapper, to go back to padded content --> 
				<!-- content goes in here -->
				More content inside inner-wrapper <br> <br>
			</div>
				
		</div><!-- end of outer wrapper -->

	</div>
	
</template>


<script>
import TopLeftIcon from '@/components/TopLeftIcon.vue'
import Menu from '@/components/Menu.vue'

export default {
    data () {
        return {
           
        }
	},
	components: {
		TopLeftIcon,
		Menu
	},
    computed: {
	
    },
    methods: {
      
	},
	mounted () {
		
	},
};
</script>


<style scoped>
	/* Borders to highlight the different areas for this example page.  */
	.inner-wrapper {
		border: 1px solid blue;
	}
	.outer-wrapper {
		border: 1px solid red;
	}
	.heading-section {
		border: 1px solid green;
	}

</style>